
                  @import '@/styles/utilities/_variables.scss';
                  @import '@/styles/utilities/_mixins.scss';
                  @import '@/styles/utilities/_functions.scss';
                
/* Disable vuetify scrollbar jumping hack */
/* https://vuetifyjs.com/en/getting-started/frequently-asked-questions/#scrollbar-overflow */
html { overflow-y: auto }

/* Disable number input up/down arrows for Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Disable number input up/down arrows for Firefox */
input[type=number] {
    -moz-appearance:textfield;
}

.d-grid {
    display: grid;
}

.gap-1 {
    gap: 4px;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px;
}

.gap-4 {
    gap: 16px;
}

.gap-5 {
    gap: 20px;
}

.gap-6 {
    gap: 24px;
}
